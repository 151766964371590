import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { useProfile } from '../hooks/useProfile';
import { PublicProfile } from '../components/profile/PublicProfile';
import { PageTitle } from '../components/layout/PageTitle';

export function CreatorProfilePage() {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();
  const { profile, loading, error } = useProfile(id || '');

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 pt-20 px-4">
        <div className="flex items-center justify-center py-12">
          <div className="w-8 h-8 border-2 border-indigo-500/20 border-t-indigo-500 rounded-full animate-spin" />
        </div>
      </div>
    );
  }

  if (error || !profile) {
    return (
      <div className="min-h-screen bg-gray-900 pt-20 px-4">
        <div className="text-center py-12 text-red-400">
          Failed to load creator profile
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle 
        title={`${profile.username}'s Profile`}
        description={`View ${profile.username}'s creator profile on Sootra Hub`}
      />

      <div className="min-h-screen bg-gray-900 pt-20 px-4">
        <PublicProfile profile={profile} username={profile.username} />
      </div>
    </>
  );
}