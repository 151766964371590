import React, { useState } from 'react';
import { Users, DollarSign, CheckCircle2, AlertCircle, Search, Filter } from 'lucide-react';
import { PageTitle } from '../layout/PageTitle';
import { ConfirmDialog } from '../shared/ConfirmDialog';
import { CampaignList } from '../campaign/CampaignList';
import { CampaignDetail } from '../campaign/CampaignDetail';
import { useAdminStats } from '../../hooks/useAdminStats';
import { useAdminTasks } from '../../hooks/useAdminTasks';
import type { User, Campaign } from '../../types';

const TASKS_PER_PAGE = 10;

interface AdminDashboardProps {
  user: User;
}

export function AdminDashboard({ user }: AdminDashboardProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState<'all' | 'pending'>('pending');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState<{ id: string; action: 'approve' | 'reject' } | null>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [activeTab, setActiveTab] = useState<'overview' | 'campaigns'>('overview');

  // Mock campaigns data
  const [campaigns] = useState<Campaign[]>([
    {
      id: 'campaign-1',
      brandId: 'brand-1',
      title: 'Summer Collection Launch',
      description: 'Promote our new summer collection featuring sustainable fashion pieces.',
      rewardAmount: 200,
      contentType: 'instagram',
      deadline: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(),
      status: 'pending',
      requirements: [
        'Minimum 10k followers',
        'High engagement rate',
        'Fashion-focused content'
      ],
      budget: 2000,
      remainingBudget: 2000
    }
  ]);

  const stats = useAdminStats();
  const { 
    tasks, 
    totalTasks,
    isLoading,
    error: tasksError,
    approveTask,
    rejectTask
  } = useAdminTasks({
    page: currentPage,
    limit: TASKS_PER_PAGE,
    searchTerm,
    filterStatus,
  });

  const handleTaskAction = (taskId: string, action: 'approve' | 'reject') => {
    setSelectedTask({ id: taskId, action });
    setShowConfirmDialog(true);
  };

  const confirmTaskAction = async () => {
    if (!selectedTask) return;

    try {
      if (selectedTask.action === 'approve') {
        await approveTask(selectedTask.id);
      } else {
        await rejectTask(selectedTask.id);
      }
    } catch (error) {
      console.error('Error processing task:', error);
    }
  };

  const handleCampaignClick = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
  };

  if (selectedCampaign) {
    return (
      <CampaignDetail
        campaign={selectedCampaign}
        onBack={() => setSelectedCampaign(null)}
      />
    );
  }

  return (
    <>
      <PageTitle 
        title="Admin Dashboard"
        description="Manage users, campaigns, and task approvals on Sootra Hub"
      />

      <div className="space-y-8">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="glass-card p-6">
            <div className="flex items-center gap-3 mb-2">
              <Users className="w-5 h-5 text-indigo-400" />
              <h3 className="text-lg font-semibold">Total Users</h3>
            </div>
            {stats.isLoading ? (
              <div className="animate-pulse h-8 bg-gray-700 rounded w-24" />
            ) : (
              <>
                <p className="text-3xl font-bold gradient-text">{stats.totalUsers}</p>
                <p className="text-sm text-green-400 mt-2">+{stats.weeklyGrowth.toFixed(1)}% this week</p>
              </>
            )}
          </div>
          
          <div className="glass-card p-6">
            <div className="flex items-center gap-3 mb-2">
              <CheckCircle2 className="w-5 h-5 text-indigo-400" />
              <h3 className="text-lg font-semibold">Active Promoters</h3>
            </div>
            {stats.isLoading ? (
              <div className="animate-pulse h-8 bg-gray-700 rounded w-24" />
            ) : (
              <>
                <p className="text-3xl font-bold gradient-text">{stats.activePromoters}</p>
                <p className="text-sm text-gray-400 mt-2">{stats.avgTaskCompletion.toFixed(1)}% completion rate</p>
              </>
            )}
          </div>
          
          <div className="glass-card p-6">
            <div className="flex items-center gap-3 mb-2">
              <DollarSign className="w-5 h-5 text-indigo-400" />
              <h3 className="text-lg font-semibold">Total Payout</h3>
            </div>
            {stats.isLoading ? (
              <div className="animate-pulse h-8 bg-gray-700 rounded w-24" />
            ) : (
              <>
                <p className="text-3xl font-bold gradient-text">${stats.totalPayout}</p>
                <p className="text-sm text-gray-400 mt-2">Across all campaigns</p>
              </>
            )}
          </div>
          
          <div className="glass-card p-6">
            <div className="flex items-center gap-3 mb-2">
              <AlertCircle className="w-5 h-5 text-indigo-400" />
              <h3 className="text-lg font-semibold">Pending Tasks</h3>
            </div>
            {stats.isLoading ? (
              <div className="animate-pulse h-8 bg-gray-700 rounded w-24" />
            ) : (
              <>
                <p className="text-3xl font-bold gradient-text">{stats.pendingTasks}</p>
                <p className="text-sm text-gray-400 mt-2">Awaiting review</p>
              </>
            )}
          </div>
        </div>

        {/* Tabs */}
        <div className="flex gap-4 border-b border-gray-800">
          <button
            onClick={() => setActiveTab('overview')}
            className={`px-4 py-2 font-medium ${
              activeTab === 'overview'
                ? 'text-indigo-400 border-b-2 border-indigo-400'
                : 'text-gray-400 hover:text-gray-300'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('campaigns')}
            className={`px-4 py-2 font-medium ${
              activeTab === 'campaigns'
                ? 'text-indigo-400 border-b-2 border-indigo-400'
                : 'text-gray-400 hover:text-gray-300'
            }`}
          >
            All Campaigns
          </button>
        </div>

        {/* Content */}
        <div className="glass-card p-6">
          {activeTab === 'overview' && (
            <>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold">Task Management</h2>
                
                <div className="flex gap-4">
                  {/* Search */}
                  <div className="relative">
                    <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search by ID or username..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  
                  {/* Filter */}
                  <div className="relative">
                    <Filter className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <select
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value as 'all' | 'pending')}
                      className="pl-10 pr-4 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
                    >
                      <option value="all">All Tasks</option>
                      <option value="pending">Pending Only</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Tasks List */}
              {/* ... Rest of the tasks list code remains the same ... */}
            </>
          )}

          {activeTab === 'campaigns' && (
            <>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold">All Campaigns</h2>
              </div>
              <CampaignList 
                campaigns={campaigns} 
                showStatus 
                onCampaignClick={handleCampaignClick}
              />
            </>
          )}
        </div>
      </div>

      {/* Confirmation Dialog */}
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onClose={() => {
          setShowConfirmDialog(false);
          setSelectedTask(null);
        }}
        onConfirm={confirmTaskAction}
        title={selectedTask?.action === 'approve' ? 'Approve Task' : 'Reject Task'}
        message={`Are you sure you want to ${selectedTask?.action} this task? This action cannot be undone.`}
        confirmText={selectedTask?.action === 'approve' ? 'Approve' : 'Reject'}
        confirmButtonClass={selectedTask?.action === 'approve' ? 'bg-green-600 hover:bg-green-500' : 'bg-red-600 hover:bg-red-500'}
      />
    </>
  );
}