import React, { useState } from 'react';
import { supabase } from '../../lib/supabase';
import { User } from '../../types';
import { ProfilePicture } from './ProfilePicture';
import { SocialLinksForm } from './SocialLinksForm';
import { BioForm } from './BioForm';

interface ProfileFormProps {
  user: User;
}

export function ProfileForm({ user }: ProfileFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleProfilePictureUpdate = async (url: string) => {
    try {
      const { error: updateError } = await supabase
        .from('users')
        .update({ profile_picture: url })
        .eq('id', user.id);

      if (updateError) throw updateError;
      
      setSuccessMessage('Profile picture updated successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update profile picture');
      throw err;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      setError('');
      setSuccessMessage('');

      // Form data will be handled by child components
      // This is just the container form

      setSuccessMessage('Profile updated successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update profile');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      {error && (
        <div className="bg-red-500/10 border border-red-500/20 text-red-400 p-4 rounded-lg">
          {error}
        </div>
      )}

      {successMessage && (
        <div className="bg-green-500/10 border border-green-500/20 text-green-400 p-4 rounded-lg">
          {successMessage}
        </div>
      )}

      {/* Profile Picture */}
      <ProfilePicture user={user} onUpdate={handleProfilePictureUpdate} />

      {/* Bio */}
      <BioForm user={user} isSubmitting={isSubmitting} />

      {/* Social Links */}
      <SocialLinksForm user={user} isSubmitting={isSubmitting} />

      <button
        type="submit"
        disabled={isSubmitting}
        className="button-primary w-full"
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
            Saving...
          </div>
        ) : (
          'Save Changes'
        )}
      </button>
    </form>
  );
}