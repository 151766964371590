import React, { useState } from 'react';
import { Plus, Minus, Link as LinkIcon } from 'lucide-react';
import { User } from '../../types';

interface SocialLink {
  platform: string;
  url: string;
}

const PLATFORMS = [
  { value: 'instagram', label: 'Instagram', urlPattern: 'instagram.com' },
  { value: 'twitter', label: 'Twitter', urlPattern: 'twitter.com' },
  { value: 'youtube', label: 'YouTube', urlPattern: 'youtube.com' },
  { value: 'facebook', label: 'Facebook', urlPattern: 'facebook.com' },
  { value: 'linkedin', label: 'LinkedIn', urlPattern: 'linkedin.com' },
  { value: 'tiktok', label: 'TikTok', urlPattern: 'tiktok.com' }
];

interface SocialLinksFormProps {
  user: User;
  isSubmitting: boolean;
}

export function SocialLinksForm({ user, isSubmitting }: SocialLinksFormProps) {
  const [socialLinks, setSocialLinks] = useState<SocialLink[]>(() => {
    const links = Object.entries(user.social_links || {}).map(([platform, url]) => ({
      platform,
      url: url || ''
    }));
    return links.length > 0 ? links : [{ platform: '', url: '' }];
  });

  const validateSocialLink = (platform: string, url: string) => {
    if (!url) return true; // Empty URLs are allowed
    try {
      const urlObj = new URL(url);
      const platformConfig = PLATFORMS.find(p => p.value === platform);
      if (!platformConfig) return true;
      return urlObj.hostname.includes(platformConfig.urlPattern);
    } catch {
      return false;
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-300 mb-2">
        Social Media Links
      </label>
      {socialLinks.map((link, index) => (
        <div key={index} className="flex gap-2 mb-2">
          <select
            value={link.platform}
            onChange={(e) => {
              const newLinks = [...socialLinks];
              newLinks[index] = { ...link, platform: e.target.value };
              setSocialLinks(newLinks);
            }}
            className="w-40 px-3 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            disabled={isSubmitting}
          >
            <option value="">Select Platform</option>
            {PLATFORMS.map(platform => (
              <option 
                key={platform.value} 
                value={platform.value}
                disabled={socialLinks.some(l => 
                  l.platform === platform.value && l !== link
                )}
              >
                {platform.label}
              </option>
            ))}
          </select>

          <div className="flex-1 relative">
            <LinkIcon className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="url"
              value={link.url}
              onChange={(e) => {
                const newLinks = [...socialLinks];
                newLinks[index] = { ...link, url: e.target.value };
                setSocialLinks(newLinks);
              }}
              className={`w-full pl-10 pr-4 py-2 rounded-lg bg-gray-800 text-white focus:ring-2 focus:ring-indigo-500 ${
                link.url && !validateSocialLink(link.platform, link.url)
                  ? 'border-red-500'
                  : ''
              }`}
              placeholder={link.platform ? `Enter your ${link.platform} profile URL` : 'Select a platform first'}
              disabled={isSubmitting || !link.platform}
            />
            {link.url && !validateSocialLink(link.platform, link.url) && (
              <p className="text-red-400 text-sm mt-1">
                Please enter a valid {PLATFORMS.find(p => p.value === link.platform)?.label} URL
              </p>
            )}
          </div>

          <button
            type="button"
            onClick={() => {
              if (socialLinks.length === 1) {
                setSocialLinks([{ platform: '', url: '' }]);
              } else {
                setSocialLinks(socialLinks.filter((_, i) => i !== index));
              }
            }}
            className="p-2 text-gray-400 hover:text-white"
            disabled={isSubmitting}
          >
            <Minus className="w-5 h-5" />
          </button>

          {index === socialLinks.length - 1 && (
            <button
              type="button"
              onClick={() => setSocialLinks([...socialLinks, { platform: '', url: '' }])}
              className="p-2 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <Plus className="w-5 h-5" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}