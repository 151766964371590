import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, CheckCircle2, DollarSign, Users, Shield, Star } from '../components/shared/icons';
import { WaitlistForm } from '../components/WaitlistForm';
import { SuccessMessage } from '../components/SuccessMessage';

export function LandingPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'brand'
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Submit to waitlist form
      const response = await fetch('/.netlify/functions/submission-created', {
        method: 'POST',
        body: JSON.stringify({ 
          payload: { 
            data: formData 
          }
        })
      });

      if (!response.ok) throw new Error('Failed to join waitlist');
      
      setShowSuccess(true);
    } catch (error) {
      console.error('Waitlist error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-6 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
        <div className="container mx-auto text-center relative">
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 blur-3xl"></div>
          
          <h1 className="text-5xl md:text-7xl font-bold mb-6 relative">
            The Future of
            <span className="block gradient-text animate-gradient">Creator Marketing</span>
          </h1>
          
          <p className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
            Connect with authentic creators and launch impactful campaigns on the most innovative creator marketplace.
          </p>

          <div className="flex justify-center gap-4 mb-8">
            <Link 
              to="/signup"
              className="button-primary"
            >
              Get Started <ArrowRight className="w-5 h-5" />
            </Link>
            <Link 
              to="/login"
              className="button-secondary"
            >
              Sign In <Users className="w-5 h-5" />
            </Link>
          </div>

          {showSuccess ? (
            <SuccessMessage />
          ) : (
            <WaitlistForm
              formData={formData}
              onChange={(field, value) => setFormData(prev => ({ ...prev, [field]: value }))}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 px-6">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">
            Why Choose <span className="gradient-text">Sootra Hub</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="glass-card p-6">
              <Shield className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Verified Creators</h3>
              <p className="text-gray-300">
                Work with authentic creators who deliver real engagement and results.
              </p>
            </div>
            <div className="glass-card p-6">
              <Users className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Smart Matching</h3>
              <p className="text-gray-300">
                Our AI matches you with the perfect creators for your brand and goals.
              </p>
            </div>
            <div className="glass-card p-6">
              <CheckCircle2 className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Quality Assurance</h3>
              <p className="text-gray-300">
                Every piece of content is reviewed to maintain high standards.
              </p>
            </div>
            <div className="glass-card p-6">
              <DollarSign className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">Secure Payments</h3>
              <p className="text-gray-300">
                Escrow payments and transparent pricing for peace of mind.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-6">
        <div className="container mx-auto text-center">
          <div className="glass-card p-12 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 blur-3xl"></div>
            <h2 className="text-4xl font-bold mb-6 relative">
              Ready to Get <span className="gradient-text">Started?</span>
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Join our platform and start connecting with amazing opportunities.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link 
                to="/signup"
                className="button-primary"
              >
                Create Account <ArrowRight className="w-5 h-5" />
              </Link>
              <Link 
                to="/login"
                className="button-secondary"
              >
                Sign In <Users className="w-5 h-5" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}