import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { User } from '../types';

// Demo account credentials
const DEMO_ACCOUNTS = {
  'demo.brand@sootrahub.com': {
    id: 'demo-brand',
    email: 'demo.brand@sootrahub.com',
    username: 'DemoBrand',
    role: 'brand',
    balance: 5000,
    created_at: new Date().toISOString(),
    social_links: {
      website: 'https://example.com'
    }
  },
  'demo.creator@sootrahub.com': {
    id: 'demo-creator',
    email: 'demo.creator@sootrahub.com',
    username: 'DemoCreator',
    role: 'promoter',
    balance: 250,
    created_at: new Date().toISOString(),
    social_links: {
      instagram: '@democreator',
      twitter: '@democreator'
    }
  }
};

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, username: string, role: string) => Promise<void>;
  signOut: () => Promise<void>;
  checkSession: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: false,
  error: null,

  signIn: async (email: string, password: string) => {
    try {
      set({ loading: true, error: null });
      
      // Check for demo accounts first
      if (email in DEMO_ACCOUNTS && password === 'demo123') {
        const demoUser = DEMO_ACCOUNTS[email as keyof typeof DEMO_ACCOUNTS];
        set({ user: demoUser, loading: false });
        return;
      }

      // Regular authentication flow
      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (authError) throw authError;

      // Get user profile data
      const { data: profileData, error: profileError } = await supabase
        .from('users')
        .select('*')
        .eq('id', authData.user.id)
        .single();

      if (profileError) throw profileError;

      const user: User = {
        id: authData.user.id,
        email: authData.user.email!,
        username: profileData.username,
        role: profileData.role,
        balance: profileData.balance,
        created_at: profileData.created_at,
        social_links: profileData.social_links
      };

      set({ user, loading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Invalid credentials';
      set({ error: message, loading: false });
      throw new Error(message);
    }
  },

  signUp: async (email: string, password: string, username: string, role: string) => {
    try {
      set({ loading: true, error: null });

      // Prevent registration with demo emails
      if (email in DEMO_ACCOUNTS) {
        throw new Error('This email is reserved for demo purposes');
      }

      // Create auth user
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { username, role }
        }
      });

      if (authError) throw authError;

      // Create user profile
      const { error: profileError } = await supabase
        .from('users')
        .insert([{
          id: authData.user!.id,
          email,
          username,
          role,
          balance: 0,
          social_links: {}
        }]);

      if (profileError) throw profileError;

      set({ loading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Registration failed';
      set({ error: message, loading: false });
      throw new Error(message);
    }
  },

  signOut: async () => {
    try {
      set({ loading: true, error: null });
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      set({ user: null, loading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Sign out failed';
      set({ error: message, loading: false });
      throw new Error(message);
    }
  },

  checkSession: async () => {
    try {
      // Check for demo user in localStorage
      const demoEmail = localStorage.getItem('demoUser');
      if (demoEmail && demoEmail in DEMO_ACCOUNTS) {
        const demoUser = DEMO_ACCOUNTS[demoEmail as keyof typeof DEMO_ACCOUNTS];
        set({ user: demoUser, loading: false });
        return;
      }

      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (!session) {
        set({ user: null, loading: false });
        return;
      }

      // Get user profile data
      const { data: profileData, error: profileError } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (profileError) throw profileError;

      const user: User = {
        id: session.user.id,
        email: session.user.email!,
        username: profileData.username,
        role: profileData.role,
        balance: profileData.balance,
        created_at: profileData.created_at,
        social_links: profileData.social_links
      };

      set({ user, loading: false });
    } catch (error) {
      console.error('Session check error:', error);
      set({ user: null, loading: false });
    }
  }
}));