import React from 'react';
import { PageTitle } from '../layout/PageTitle';

export function PaymentTerms() {
  return (
    <>
      <PageTitle 
        title="Payment Terms"
        description="Payment processing, fees, and payout information"
      />

      <div className="glass-card p-8 space-y-6">
        <h1 className="text-3xl font-bold mb-8">Payment Terms</h1>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">1. Platform Fees</h2>
          <div className="space-y-2 text-gray-300">
            <p>1.1. Brands: 10% of campaign budget</p>
            <p>1.2. Creators: 5% of earned amount</p>
            <p>1.3. Fee calculation and collection</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">2. Payment Processing</h2>
          <div className="space-y-2 text-gray-300">
            <p>2.1. Secure blockchain-based escrow system</p>
            <p>2.2. Supported payment methods</p>
            <p>2.3. Currency and conversion rates</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">3. Campaign Funding</h2>
          <div className="space-y-2 text-gray-300">
            <p>3.1. Upfront budget requirement</p>
            <p>3.2. Fund allocation and holds</p>
            <p>3.3. Refund conditions</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">4. Creator Payouts</h2>
          <div className="space-y-2 text-gray-300">
            <p>4.1. Minimum payout threshold: $10</p>
            <p>4.2. Payout schedule and methods</p>
            <p>4.3. Tax implications and reporting</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">5. Escrow System</h2>
          <div className="space-y-2 text-gray-300">
            <p>5.1. Smart contract functionality</p>
            <p>5.2. Fund release conditions</p>
            <p>5.3. Dispute handling</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">6. Security Measures</h2>
          <div className="space-y-2 text-gray-300">
            <p>6.1. Payment data protection</p>
            <p>6.2. Fraud prevention</p>
            <p>6.3. Transaction monitoring</p>
          </div>
        </section>

        <div className="mt-8 p-4 bg-gray-800 rounded-lg">
          <p className="text-sm text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </>
  );
}