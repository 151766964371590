import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../components/layout/PageTitle';
import { AuthForm } from '../components/auth/AuthForm';
import { useAuthStore } from '../stores/authStore';
import { Megaphone, Info } from 'lucide-react';

export function LoginPage() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, loading } = useAuthStore();

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    try {
      setError('');
      await signIn(email, password);
      navigate('/dashboard');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to sign in');
    }
  };

  return (
    <>
      <PageTitle 
        title="Sign In" 
        description="Sign in to your Sootra Hub account"
      />

      <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
        <div className="w-full max-w-md">
          <div className="text-center mb-8">
            <div className="flex items-center justify-center gap-2 mb-6">
              <div className="p-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg">
                <Megaphone className="w-6 h-6 text-white" />
              </div>
              <span className="text-2xl font-bold gradient-text">Sootra Hub</span>
            </div>
            <h1 className="text-3xl font-bold text-white mb-2">Welcome Back</h1>
            <p className="text-gray-400">Sign in to your account</p>
          </div>

          <div className="glass-card p-8">
            <AuthForm 
              type="login"
              onSubmit={handleLogin}
              isLoading={loading}
              serverError={error}
            />

            {/* Demo Account Info */}
            <div className="mt-8 p-4 bg-indigo-500/10 border border-indigo-500/20 rounded-lg">
              <div className="flex items-start gap-2">
                <Info className="w-5 h-5 text-indigo-400 mt-0.5" />
                <div>
                  <h3 className="font-medium text-indigo-400 mb-2">Demo Accounts</h3>
                  <div className="space-y-2 text-sm text-gray-300">
                    <p>Brand: demo.brand@sootrahub.com / demo123</p>
                    <p>Creator: demo.creator@sootrahub.com / demo123</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 text-center text-gray-400">
              Don't have an account?{' '}
              <Link to="/signup" className="text-indigo-400 hover:text-indigo-300">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}