import React from 'react';
import { PageTitle } from '../layout/PageTitle';

export function TermsOfService() {
  return (
    <>
      <PageTitle 
        title="Terms of Service"
        description="Terms and conditions for using Sootra Hub's services"
      />

      <div className="glass-card p-8 space-y-6">
        <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
        
        <section className="space-y-4">
          <h2 className="text-xl font-semibold">1. Acceptance of Terms</h2>
          <p className="text-gray-300">
            By accessing and using Sootra Hub's services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
          </p>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">2. Service Description</h2>
          <p className="text-gray-300">
            Sootra Hub is a platform that connects brands with content creators for promotional campaigns and sponsored content creation.
          </p>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">3. User Accounts</h2>
          <div className="space-y-2 text-gray-300">
            <p>3.1. You must be at least 18 years old to use our services.</p>
            <p>3.2. You are responsible for maintaining the security of your account credentials.</p>
            <p>3.3. You must provide accurate and complete information when creating an account.</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">4. Content Guidelines</h2>
          <div className="space-y-2 text-gray-300">
            <p>4.1. You retain ownership of your content.</p>
            <p>4.2. You grant Sootra Hub a license to use, display, and distribute your content.</p>
            <p>4.3. Content must not violate any laws or third-party rights.</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">5. Platform Rules</h2>
          <div className="space-y-2 text-gray-300">
            <p>5.1. No fraudulent or deceptive practices.</p>
            <p>5.2. No harassment or abuse of other users.</p>
            <p>5.3. No manipulation of platform metrics or engagement.</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">6. Termination</h2>
          <p className="text-gray-300">
            We reserve the right to suspend or terminate accounts that violate these terms or engage in harmful behavior.
          </p>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">7. Changes to Terms</h2>
          <p className="text-gray-300">
            We may update these terms from time to time. Continued use of the platform after changes constitutes acceptance of the new terms.
          </p>
        </section>

        <div className="mt-8 p-4 bg-gray-800 rounded-lg">
          <p className="text-sm text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </>
  );
}