import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { BrandDashboard } from '../components/dashboard/BrandDashboard';
import { UserDashboard } from '../components/dashboard/UserDashboard';
import { AdminDashboard } from '../components/dashboard/AdminDashboard';

export function DashboardPage() {
  const { user } = useAuthStore();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="pt-20 px-4 min-h-screen bg-gray-900">
      <div className="max-w-7xl mx-auto">
        {user.role === 'admin' ? (
          <AdminDashboard user={user} />
        ) : user.role === 'brand' ? (
          <BrandDashboard user={user} />
        ) : (
          <UserDashboard user={user} />
        )}
      </div>
    </div>
  );
}