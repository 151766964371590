import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import { UserRole } from '../../types';

interface AuthFormProps {
  type: 'login' | 'register';
  onSubmit: (data: { email: string; password: string; username?: string; role?: UserRole }) => void;
  isLoading?: boolean;
  serverError?: string;
}

export function AuthForm({ type, onSubmit, isLoading, serverError }: AuthFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState<UserRole>('promoter');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!password) {
      newErrors.password = 'Password is required';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    if (type === 'register' && !username) {
      newErrors.username = 'Username is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    onSubmit({
      email,
      password,
      ...(type === 'register' ? { username, role } : {})
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {(serverError || Object.keys(errors).length > 0) && (
        <div className="bg-red-500/10 border border-red-500/20 text-red-400 p-4 rounded-lg">
          {serverError && (
            <div className="flex items-center gap-2 mb-2">
              <AlertCircle className="w-5 h-5" />
              <span>{serverError}</span>
            </div>
          )}
          {Object.values(errors).map((error, index) => (
            <div key={index} className="text-sm ml-7">• {error}</div>
          ))}
        </div>
      )}

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
          Email Address
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
          disabled={isLoading}
          required
        />
      </div>

      {type === 'register' && (
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-300 mb-2">
            Username
          </label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            disabled={isLoading}
            required
          />
        </div>
      )}

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
          Password
        </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
          disabled={isLoading}
          required
        />
      </div>

      {type === 'register' && (
        <div>
          <label htmlFor="role" className="block text-sm font-medium text-gray-300 mb-2">
            I am a...
          </label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value as UserRole)}
            className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            disabled={isLoading}
            required
          >
            <option value="promoter">Content Creator</option>
            <option value="brand">Brand</option>
          </select>
        </div>
      )}

      <button
        type="submit"
        className="button-primary w-full"
        disabled={isLoading}
      >
        {isLoading ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
            {type === 'login' ? 'Signing In...' : 'Creating Account...'}
          </div>
        ) : (
          type === 'login' ? 'Sign In' : 'Create Account'
        )}
      </button>
    </form>
  );
}