import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../components/layout/PageTitle';
import { AuthForm } from '../components/auth/AuthForm';
import { useAuthStore } from '../stores/authStore';
import type { UserRole } from '../types';

export function SignupPage() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const signUp = useAuthStore(state => state.signUp);
  const loading = useAuthStore(state => state.loading);

  const handleSignup = async ({ 
    email, 
    password, 
    username, 
    role 
  }: { 
    email: string; 
    password: string; 
    username?: string; 
    role?: UserRole 
  }) => {
    if (!username || !role) return;
    
    try {
      setError('');
      await signUp(email, password, username, role);
      navigate('/login', { 
        state: { message: 'Account created successfully. Please sign in.' } 
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create account');
    }
  };

  return (
    <>
      <PageTitle 
        title="Create Account" 
        description="Join Sootra Hub as a brand or content creator"
      />

      <div className="min-h-[calc(100vh-4rem)] flex items-center justify-center p-4">
        <div className="w-full max-w-md">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-white mb-2">Create Account</h1>
            <p className="text-gray-400">Join our community of brands and creators</p>
          </div>

          <div className="glass-card p-8">
            <AuthForm 
              type="register"
              onSubmit={handleSignup}
              isLoading={loading}
              serverError={error}
            />

            <div className="mt-6 text-center text-gray-400">
              Already have an account?{' '}
              <Link to="/login" className="text-indigo-400 hover:text-indigo-300">
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}