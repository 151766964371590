import { useState, useEffect } from 'react';

interface AdminStats {
  totalUsers: number;
  activePromoters: number;
  totalPayout: number;
  pendingTasks: number;
  weeklyGrowth: number;
  avgTaskCompletion: number;
  isLoading: boolean;
  error: string | null;
}

// Mock data for admin statistics
const mockStats: Omit<AdminStats, 'isLoading' | 'error'> = {
  totalUsers: 156,
  activePromoters: 89,
  totalPayout: 25680,
  pendingTasks: 34,
  weeklyGrowth: 12.5,
  avgTaskCompletion: 87.3,
};

export function useAdminStats() {
  const [stats, setStats] = useState<AdminStats>({
    ...mockStats,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    // Simulate API delay
    const timer = setTimeout(() => {
      setStats({
        ...mockStats,
        isLoading: false,
        error: null,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return stats;
}