import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Users, FileCheck, ClipboardList, DollarSign } from 'lucide-react';
import { mockCampaigns } from '../../lib/mockData';

export function CampaignDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const campaign = mockCampaigns.find(c => c.id === id);

  if (!campaign) {
    return (
      <div className="text-center py-12 text-gray-400">
        Campaign not found
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <button
            onClick={() => navigate('/dashboard')}
            className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-4"
          >
            <ArrowLeft className="w-5 h-5" />
            Back to Dashboard
          </button>
          <h1 className="text-2xl font-bold">{campaign.title}</h1>
        </div>
        <span className={`px-3 py-1 rounded-full text-sm ${
          campaign.status === 'active'
            ? 'bg-green-500/10 text-green-400'
            : campaign.status === 'draft'
            ? 'bg-gray-500/10 text-gray-400'
            : 'bg-yellow-500/10 text-yellow-400'
        }`}>
          {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
        </span>
      </div>

      {/* Campaign Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="glass-card p-6">
          <div className="flex items-center gap-3 mb-2">
            <Users className="w-5 h-5 text-indigo-400" />
            <h3 className="text-lg font-semibold">Budget</h3>
          </div>
          <p className="text-3xl font-bold gradient-text">
            ${campaign.budget?.toLocaleString()}
          </p>
          <p className="text-sm text-gray-400 mt-2">
            ${campaign.remainingBudget?.toLocaleString()} remaining
          </p>
        </div>

        <div className="glass-card p-6">
          <div className="flex items-center gap-3 mb-2">
            <DollarSign className="w-5 h-5 text-indigo-400" />
            <h3 className="text-lg font-semibold">Reward per Task</h3>
          </div>
          <p className="text-3xl font-bold gradient-text">
            ${campaign.rewardAmount}
          </p>
          <p className="text-sm text-gray-400 mt-2">Fixed rate</p>
        </div>

        <div className="glass-card p-6">
          <div className="flex items-center gap-3 mb-2">
            <FileCheck className="w-5 h-5 text-indigo-400" />
            <h3 className="text-lg font-semibold">Deadline</h3>
          </div>
          <p className="text-3xl font-bold gradient-text">
            {new Date(campaign.deadline).toLocaleDateString()}
          </p>
          <p className="text-sm text-gray-400 mt-2">
            {new Date(campaign.deadline).toLocaleTimeString()}
          </p>
        </div>
      </div>

      {/* Campaign Details */}
      <div className="glass-card p-6">
        <h2 className="text-xl font-bold mb-4">Campaign Details</h2>
        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium text-gray-400">Description</h3>
            <p className="mt-1 text-gray-300">{campaign.description}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Content Type</h3>
            <p className="mt-1 text-gray-300">{campaign.contentType}</p>
          </div>

          {campaign.requirements && campaign.requirements.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-400">Requirements</h3>
              <ul className="mt-1 space-y-1">
                {campaign.requirements.map((req, index) => (
                  <li key={index} className="text-gray-300">• {req}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Actions */}
      <div className="flex justify-end gap-4">
        <button className="button-secondary">
          Edit Campaign
        </button>
        <button className="button-primary">
          View Applications
        </button>
      </div>
    </div>
  );
}