import React from 'react';
import { ArrowRight } from 'lucide-react';

interface WaitlistFormProps {
  formData: {
    name: string;
    email: string;
    role: string;
  };
  onChange: (field: 'name' | 'email' | 'role', value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
  isSubmitting: boolean;
  error?: string;
}

export function WaitlistForm({ formData, onChange, onSubmit, isSubmitting, error }: WaitlistFormProps) {
  return (
    <form 
      onSubmit={onSubmit} 
      className="max-w-md mx-auto space-y-4"
      name="waitlist"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="waitlist" />
      <input type="hidden" name="bot-field" />

      {error && (
        <div className="bg-red-500/10 border border-red-500/20 text-red-400 p-4 rounded-lg">
          {error}
        </div>
      )}

      <div>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={(e) => onChange('name', e.target.value)}
          placeholder="Your Name"
          className="w-full px-6 py-4 bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={(e) => onChange('email', e.target.value)}
          placeholder="Your Email"
          className="w-full px-6 py-4 bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <select
          name="role"
          value={formData.role}
          onChange={(e) => onChange('role', e.target.value)}
          className="w-full px-6 py-4 bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl text-white focus:ring-2 focus:ring-indigo-500"
        >
          <option value="brand">I am a Brand</option>
          <option value="creator">I am a Creator</option>
        </select>
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full button-primary py-4 text-lg"
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
            Joining...
          </div>
        ) : (
          <div className="flex items-center justify-center gap-2">
            Join Waitlist <ArrowRight className="w-5 h-5" />
          </div>
        )}
      </button>
    </form>
  );
}