import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../components/layout/PageTitle';
import { ProfileForm } from '../components/profile/ProfileForm';
import { WalletSettings } from '../components/profile/WalletSettings';
import { useAuthStore } from '../stores/authStore';
import { ArrowLeft } from 'lucide-react';

export function ProfilePage() {
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user);
  const [activeTab, setActiveTab] = useState<'profile' | 'wallet'>('profile');

  if (!user) {
    return null;
  }

  return (
    <>
      <PageTitle 
        title="Profile Settings" 
        description="Manage your profile and payment settings"
      />

      <div className="max-w-4xl mx-auto px-4 py-8">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate('/dashboard')}
            className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-4"
          >
            <ArrowLeft className="w-5 h-5" />
            Back to Dashboard
          </button>
          <h1 className="text-2xl font-bold">Profile Settings</h1>
        </div>

        {/* Tabs */}
        <div className="flex gap-4 border-b border-gray-800 mb-8">
          <button
            onClick={() => setActiveTab('profile')}
            className={`px-4 py-2 font-medium ${
              activeTab === 'profile'
                ? 'text-indigo-400 border-b-2 border-indigo-400'
                : 'text-gray-400 hover:text-gray-300'
            }`}
          >
            Profile
          </button>
          <button
            onClick={() => setActiveTab('wallet')}
            className={`px-4 py-2 font-medium ${
              activeTab === 'wallet'
                ? 'text-indigo-400 border-b-2 border-indigo-400'
                : 'text-gray-400 hover:text-gray-300'
            }`}
          >
            Wallet Settings
          </button>
        </div>

        {/* Content */}
        <div className="glass-card p-6">
          {activeTab === 'profile' ? (
            <ProfileForm user={user} />
          ) : (
            <WalletSettings user={user} />
          )}
        </div>
      </div>
    </>
  );
}