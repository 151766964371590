import React, { useState } from 'react';
import { User } from '../../types';
import { PaymentMethodForm } from '../payment/PaymentMethodForm';
import { PaymentMethodList } from '../payment/PaymentMethodList';
import { Plus } from 'lucide-react';

interface WalletSettingsProps {
  user: User;
}

export function WalletSettings({ user }: WalletSettingsProps) {
  const [showAddMethod, setShowAddMethod] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState<string>('');
  const [error, setError] = useState('');

  // Mock payment methods
  const paymentMethods = [
    {
      id: 'pm_1',
      type: 'bank_transfer',
      details: {
        accountName: 'John Doe',
        accountNumber: '****1234',
        bankName: 'Chase'
      },
      isDefault: true
    }
  ];

  const handleAddMethod = async (method: any) => {
    try {
      setError('');
      // In production, this would save to your backend
      console.log('Adding payment method:', method);
      setShowAddMethod(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add payment method');
    }
  };

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-500/10 border border-red-500/20 text-red-400 p-4 rounded-lg">
          {error}
        </div>
      )}

      {showAddMethod ? (
        <>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Add Payment Method</h3>
            <button
              onClick={() => setShowAddMethod(false)}
              className="text-sm text-gray-400 hover:text-white"
            >
              Cancel
            </button>
          </div>
          <PaymentMethodForm
            onSubmit={handleAddMethod}
            type="bank_transfer"
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Payment Methods</h3>
            <button
              onClick={() => setShowAddMethod(true)}
              className="flex items-center gap-1 text-sm text-indigo-400 hover:text-indigo-300"
            >
              <Plus className="w-4 h-4" />
              Add New
            </button>
          </div>

          <PaymentMethodList
            methods={paymentMethods}
            onSelect={(method) => setSelectedMethodId(method.id)}
            selectedId={selectedMethodId}
          />

          <div className="bg-yellow-500/10 border border-yellow-500/20 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-yellow-400 mb-2">Important Note</h4>
            <p className="text-sm text-yellow-400/90">
              Your payment information is securely stored and used only for receiving payouts from completed tasks.
              We never share your financial information with brands or other users.
            </p>
          </div>
        </>
      )}
    </div>
  );
}