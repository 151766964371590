import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Award, TrendingUp, Target, Clock } from 'lucide-react';
import { User } from '../../types';
import { WalletCard } from './WalletCard';
import { CampaignList } from '../campaign/CampaignList';
import { mockCampaigns } from '../../lib/mockData';

interface UserDashboardProps {
  user: User;
}

export function UserDashboard({ user }: UserDashboardProps) {
  const [campaigns] = useState(mockCampaigns);

  return (
    <div className="space-y-8">
      {/* Quick Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
        {[
          {
            icon: Award,
            title: 'Success Rate',
            value: '95%',
            trend: '+5%',
            description: 'Approval rate'
          },
          {
            icon: TrendingUp,
            title: 'Total Earned',
            value: '$1,250',
            trend: '+12%',
            description: 'This month'
          },
          {
            icon: Target,
            title: 'Completed',
            value: '12',
            trend: '+3',
            description: 'Campaigns'
          },
          {
            icon: Clock,
            title: 'Active Tasks',
            value: '3',
            trend: 'Due soon',
            description: 'In progress'
          }
        ].map((stat, index) => (
          <div key={index} className="glass-card p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 rounded-lg bg-gradient-to-br from-indigo-500/20 to-purple-500/20">
                <stat.icon className="w-5 h-5 text-indigo-400" />
              </div>
              <h3 className="text-lg font-semibold">{stat.title}</h3>
            </div>
            <div className="flex items-end justify-between">
              <div>
                <p className="text-3xl font-bold gradient-text">{stat.value}</p>
                <p className="text-sm text-gray-400 mt-1">{stat.description}</p>
              </div>
              <div className="text-sm text-green-400">
                {stat.trend}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Wallet Card */}
      <WalletCard
        balance={user.balance || 0}
        onRequestPayout={() => console.log('Request payout')}
        minPayoutAmount={10}
      />

      {/* Available Campaigns */}
      <div className="glass-card p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-bold mb-1">Available Campaigns</h2>
            <p className="text-sm text-gray-400">Matched to your profile and engagement rate</p>
          </div>
          <select className="px-4 py-2 bg-gray-800 rounded-lg border border-gray-700">
            <option>All Categories</option>
            <option>Fashion</option>
            <option>Beauty</option>
            <option>Technology</option>
          </select>
        </div>

        <CampaignList 
          campaigns={campaigns}
          onAccept={(campaignId) => console.log('Applied to campaign:', campaignId)}
          compact
        />
      </div>
    </div>
  );
}