import React from 'react';
import { Link } from 'react-router-dom';
import { Megaphone } from '../shared/icons';

export function Footer() {
  const handleLinkClick = () => {
    // Scroll to top with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="py-12 px-6 bg-gray-900">
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center gap-8">
          {/* Logo */}
          <div className="flex items-center gap-2">
            <div className="p-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg">
              <Megaphone className="w-6 h-6 text-white" />
            </div>
            <span className="text-xl font-bold gradient-text">Sootra Hub</span>
          </div>

          {/* Links */}
          <div className="flex flex-wrap justify-center gap-6 text-gray-400">
            <Link 
              to="/legal/privacy" 
              className="hover:text-white transition-colors"
              onClick={handleLinkClick}
            >
              Privacy Policy
            </Link>
            <Link 
              to="/legal/terms" 
              className="hover:text-white transition-colors"
              onClick={handleLinkClick}
            >
              Terms of Service
            </Link>
            <Link 
              to="/legal" 
              className="hover:text-white transition-colors"
              onClick={handleLinkClick}
            >
              Legal
            </Link>
          </div>

          {/* Copyright */}
          <div className="text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} Sootra Hub. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}