import React, { useState, useRef } from 'react';
import { Camera, X } from 'lucide-react';
import { User } from '../../types';
import { Avatar } from './Avatar';
import { supabase } from '../../lib/supabase';

interface ProfilePictureProps {
  user: User;
  onUpdate: (url: string) => Promise<void>;
}

export function ProfilePicture({ user, onUpdate }: ProfilePictureProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please select an image file');
      return;
    }

    // Validate file size (5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('Image must be less than 5MB');
      return;
    }

    try {
      setIsUploading(true);
      setError(null);

      // Upload to Supabase Storage
      const fileName = `${user.id}-${Date.now()}`;
      const { data, error: uploadError } = await supabase.storage
        .from('profile-pictures')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('profile-pictures')
        .getPublicUrl(fileName);

      // Update user profile
      await onUpdate(publicUrl);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsUploading(true);
      setError(null);
      await onUpdate('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to remove image');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-300 mb-4">
        Profile Picture
      </label>

      {error && (
        <div className="mb-4 text-sm text-red-400 bg-red-400/10 p-3 rounded-lg">
          {error}
        </div>
      )}

      <div className="flex items-center gap-6">
        <div className="relative">
          <Avatar user={user} size="xl" />
          
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={isUploading}
            className="absolute bottom-0 right-0 p-2 bg-indigo-500 rounded-full text-white hover:bg-indigo-400 transition-colors disabled:opacity-50"
          >
            <Camera className="w-4 h-4" />
          </button>
        </div>

        <div className="flex flex-col gap-2">
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={isUploading}
            className="text-sm text-indigo-400 hover:text-indigo-300 transition-colors disabled:opacity-50"
          >
            {isUploading ? 'Uploading...' : 'Upload New Picture'}
          </button>

          {user.profilePicture && (
            <button
              type="button"
              onClick={handleRemove}
              disabled={isUploading}
              className="text-sm text-red-400 hover:text-red-300 transition-colors disabled:opacity-50"
            >
              Remove Picture
            </button>
          )}
        </div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="hidden"
        disabled={isUploading}
      />

      <p className="mt-2 text-sm text-gray-400">
        Recommended: Square image, at least 400x400px. Maximum size: 5MB.
      </p>
    </div>
  );
}