import { useState, useEffect, useCallback } from 'react';
import type { Task } from '../types';

interface UseAdminTasksProps {
  page: number;
  limit: number;
  searchTerm: string;
  filterStatus: 'all' | 'pending';
}

interface UseAdminTasksReturn {
  tasks: Task[];
  totalTasks: number;
  isLoading: boolean;
  error: string | null;
  approveTask: (taskId: string) => Promise<void>;
  rejectTask: (taskId: string, feedback?: string) => Promise<void>;
}

// Mock tasks data
const mockTasks: Task[] = Array.from({ length: 50 }, (_, i) => ({
  id: `task-${i + 1}`,
  campaignId: `campaign-${Math.floor(i / 5) + 1}`,
  promoterId: `promoter-${Math.floor(Math.random() * 10) + 1}`,
  status: Math.random() > 0.7 ? 'approved' : Math.random() > 0.5 ? 'rejected' : 'pending',
  proofUrl: `https://example.com/proof/${i + 1}`,
  screenshot_url: Math.random() > 0.5 ? `https://example.com/screenshot/${i + 1}` : null,
  submitted_at: new Date(Date.now() - Math.random() * 10 * 24 * 60 * 60 * 1000).toISOString(),
  reviewed_at: null,
  reviewer_id: null,
  feedback: null
}));

export function useAdminTasks({ 
  page, 
  limit, 
  searchTerm, 
  filterStatus 
}: UseAdminTasksProps): UseAdminTasksReturn {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTasks = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 500));

      // Filter tasks
      let filteredTasks = [...mockTasks];
      
      if (filterStatus === 'pending') {
        filteredTasks = filteredTasks.filter(task => task.status === 'pending');
      }

      if (searchTerm) {
        filteredTasks = filteredTasks.filter(task => 
          task.id.includes(searchTerm) || 
          task.promoterId.includes(searchTerm)
        );
      }

      // Apply pagination
      const start = (page - 1) * limit;
      const end = start + limit;
      const paginatedTasks = filteredTasks.slice(start, end);

      setTasks(paginatedTasks);
      setTotalTasks(filteredTasks.length);
      setError(null);
    } catch (err) {
      setError('Failed to fetch tasks');
      console.error('Error fetching tasks:', err);
    } finally {
      setIsLoading(false);
    }
  }, [page, limit, searchTerm, filterStatus]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const approveTask = async (taskId: string) => {
    try {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 500)); // Simulate API delay
      
      setTasks(prevTasks => 
        prevTasks.map(task => 
          task.id === taskId 
            ? {
                ...task,
                status: 'approved',
                reviewed_at: new Date().toISOString(),
                reviewer_id: 'admin-1'
              }
            : task
        )
      );
    } catch (err) {
      setError('Failed to approve task');
      console.error('Error approving task:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const rejectTask = async (taskId: string, feedback?: string) => {
    try {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 500)); // Simulate API delay
      
      setTasks(prevTasks => 
        prevTasks.map(task => 
          task.id === taskId 
            ? {
                ...task,
                status: 'rejected',
                reviewed_at: new Date().toISOString(),
                reviewer_id: 'admin-1',
                feedback
              }
            : task
        )
      );
    } catch (err) {
      setError('Failed to reject task');
      console.error('Error rejecting task:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    tasks,
    totalTasks,
    isLoading,
    error,
    approveTask,
    rejectTask
  };
}