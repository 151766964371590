import { User } from '../types';

// Generate a random color for the default avatar background
const getRandomColor = () => {
  const colors = [
    'from-indigo-500 to-purple-500',
    'from-blue-500 to-indigo-500',
    'from-green-500 to-teal-500',
    'from-purple-500 to-pink-500',
    'from-red-500 to-orange-500'
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

// Get profile picture URL or generate default avatar URL
export const getProfilePicture = (user: User) => {
  if (user.profilePicture) {
    return user.profilePicture;
  }

  // Use DiceBear API for consistent avatar generation based on user ID
  return `https://api.dicebear.com/7.x/avatars/svg?seed=${user.id}&backgroundColor=transparent&radius=50`;
};

// Get initials from username
export const getInitials = (username: string) => {
  return username
    .split(/\s+/)
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

// Get background gradient for default avatar
export const getAvatarBackground = (userId: string) => {
  // Use user ID to consistently get the same color for a user
  const colorIndex = userId.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const colors = [
    'from-indigo-500 to-purple-500',
    'from-blue-500 to-indigo-500',
    'from-green-500 to-teal-500',
    'from-purple-500 to-pink-500',
    'from-red-500 to-orange-500'
  ];
  return colors[colorIndex % colors.length];
};