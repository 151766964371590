import React from 'react';
import { PageTitle } from '../layout/PageTitle';

export function BrandAgreement() {
  return (
    <>
      <PageTitle 
        title="Brand Agreement"
        description="Terms and conditions for brands and advertisers"
      />

      <div className="glass-card p-8 space-y-6">
        <h1 className="text-3xl font-bold mb-8">Brand Agreement</h1>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">1. Campaign Management</h2>
          <div className="space-y-2 text-gray-300">
            <p>1.1. Clear campaign requirements and guidelines</p>
            <p>1.2. Reasonable deadlines and expectations</p>
            <p>1.3. Transparent budget allocation</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">2. Creator Relations</h2>
          <div className="space-y-2 text-gray-300">
            <p>2.1. Fair treatment of creators</p>
            <p>2.2. Clear communication and feedback</p>
            <p>2.3. Timely review of submissions</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">3. Payment Terms</h2>
          <div className="space-y-2 text-gray-300">
            <p>3.1. Upfront campaign funding</p>
            <p>3.2. 10% platform fee on campaign budget</p>
            <p>3.3. Payment release conditions</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">4. Content Rights</h2>
          <div className="space-y-2 text-gray-300">
            <p>4.1. Usage rights for approved content</p>
            <p>4.2. Content licensing terms</p>
            <p>4.3. Attribution requirements</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">5. Campaign Standards</h2>
          <div className="space-y-2 text-gray-300">
            <p>5.1. Compliance with platform guidelines</p>
            <p>5.2. Prohibited products and services</p>
            <p>5.3. Content approval criteria</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">6. Dispute Resolution</h2>
          <div className="space-y-2 text-gray-300">
            <p>6.1. Mediation process</p>
            <p>6.2. Refund conditions</p>
            <p>6.3. Appeal procedures</p>
          </div>
        </section>

        <div className="mt-8 p-4 bg-gray-800 rounded-lg">
          <p className="text-sm text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </>
  );
}