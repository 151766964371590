import React from 'react';
import { PageTitle } from '../layout/PageTitle';

export function CreatorAgreement() {
  return (
    <>
      <PageTitle 
        title="Creator Agreement"
        description="Terms and conditions for content creators"
      />

      <div className="glass-card p-8 space-y-6">
        <h1 className="text-3xl font-bold mb-8">Creator Agreement</h1>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">1. Content Creation</h2>
          <div className="space-y-2 text-gray-300">
            <p>1.1. Create original, high-quality content</p>
            <p>1.2. Follow brand guidelines and requirements</p>
            <p>1.3. Meet campaign deadlines and specifications</p>
            <p>1.4. Maintain authenticity and transparency</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">2. Campaign Participation</h2>
          <div className="space-y-2 text-gray-300">
            <p>2.1. Apply only to relevant campaigns</p>
            <p>2.2. Maintain professional communication</p>
            <p>2.3. Disclose sponsored content appropriately</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">3. Compensation</h2>
          <div className="space-y-2 text-gray-300">
            <p>3.1. Payment upon successful completion</p>
            <p>3.2. 5% platform fee on earnings</p>
            <p>3.3. Payment processing times and methods</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">4. Content Rights</h2>
          <div className="space-y-2 text-gray-300">
            <p>4.1. License to use content for campaign purposes</p>
            <p>4.2. Retention of intellectual property rights</p>
            <p>4.3. Content usage restrictions</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">5. Performance Standards</h2>
          <div className="space-y-2 text-gray-300">
            <p>5.1. Maintain engagement metrics</p>
            <p>5.2. Respond to brand feedback</p>
            <p>5.3. Quality assurance requirements</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">6. Account Standing</h2>
          <div className="space-y-2 text-gray-300">
            <p>6.1. Performance rating system</p>
            <p>6.2. Violation consequences</p>
            <p>6.3. Dispute resolution process</p>
          </div>
        </section>

        <div className="mt-8 p-4 bg-gray-800 rounded-lg">
          <p className="text-sm text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </>
  );
}