import React from 'react';
import { getProfilePicture, getInitials, getAvatarBackground } from '../../lib/profile';
import type { User } from '../../types';

interface AvatarProps {
  user: User;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

export function Avatar({ user, size = 'md', className = '' }: AvatarProps) {
  const sizeClasses = {
    sm: 'w-8 h-8 text-sm',
    md: 'w-12 h-12 text-base',
    lg: 'w-16 h-16 text-xl',
    xl: 'w-24 h-24 text-3xl'
  };

  const profilePicture = getProfilePicture(user);
  const initials = getInitials(user.username);
  const background = getAvatarBackground(user.id);

  return (
    <div className={`${sizeClasses[size]} rounded-full overflow-hidden ${className}`}>
      {profilePicture ? (
        <img
          src={profilePicture}
          alt={user.username}
          className="w-full h-full object-cover"
        />
      ) : (
        <div className={`w-full h-full bg-gradient-to-br ${background} flex items-center justify-center font-bold text-white`}>
          {initials}
        </div>
      )}
    </div>
  );
}