import React from 'react';
import { useParams, Link, Routes, Route } from 'react-router-dom';
import { PageTitle } from '../layout/PageTitle';
import { ArrowLeft, FileText, Shield, ContractIcon, DollarSign } from '../shared/icons';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfService } from './TermsOfService';
import { CreatorAgreement } from './CreatorAgreement';
import { BrandAgreement } from './BrandAgreement';
import { PaymentTerms } from './PaymentTerms';
import { useScrollToTop } from '../../hooks/useScrollToTop';

export function LegalPage() {
  useScrollToTop();

  return (
    <div className="min-h-screen bg-gray-900 py-12">
      <div className="container mx-auto px-4">
        <Routes>
          <Route path="/" element={<LegalIndex />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/creator-agreement" element={<CreatorAgreement />} />
          <Route path="/brand-agreement" element={<BrandAgreement />} />
          <Route path="/payment-terms" element={<PaymentTerms />} />
        </Routes>
      </div>
    </div>
  );
}

function LegalIndex() {
  return (
    <>
      <PageTitle 
        title="Legal Documents"
        description="Important legal information and agreements for Sootra Hub users"
      />
      
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">Legal Documents</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Link to="/legal/terms" className="glass-card p-6 hover:bg-gray-800/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <FileText className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Terms of Service</h2>
            </div>
            <p className="text-gray-400">
              Our terms and conditions for using Sootra Hub's services
            </p>
          </Link>

          <Link to="/legal/privacy" className="glass-card p-6 hover:bg-gray-800/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <Shield className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Privacy Policy</h2>
            </div>
            <p className="text-gray-400">
              How we collect, use, and protect your personal information
            </p>
          </Link>

          <Link to="/legal/creator-agreement" className="glass-card p-6 hover:bg-gray-800/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <ContractIcon className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Creator Agreement</h2>
            </div>
            <p className="text-gray-400">
              Terms and conditions for content creators
            </p>
          </Link>

          <Link to="/legal/brand-agreement" className="glass-card p-6 hover:bg-gray-800/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <ContractIcon className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Brand Agreement</h2>
            </div>
            <p className="text-gray-400">
              Terms and conditions for brands and advertisers
            </p>
          </Link>

          <Link to="/legal/payment-terms" className="glass-card p-6 hover:bg-gray-800/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <DollarSign className="w-6 h-6 text-indigo-400" />
              <h2 className="text-xl font-semibold">Payment Terms</h2>
            </div>
            <p className="text-gray-400">
              Payment processing, fees, and payout information
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}