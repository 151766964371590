import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { CreatorProfile } from '../types/profile';

export function useProfile(userId: string) {
  const [profile, setProfile] = useState<CreatorProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      
      // For demo purposes, return mock data
      const mockProfile: CreatorProfile = {
        id: userId,
        userId,
        bio: "I am a content creator specializing in tech reviews and tutorials.",
        profilePicture: `https://api.dicebear.com/7.x/avatars/svg?seed=${userId}`,
        socialLinks: {
          instagram: 'https://instagram.com/democreator',
          twitter: 'https://twitter.com/democreator',
          youtube: 'https://youtube.com/democreator'
        },
        isComplete: true
      };

      setProfile(mockProfile);
      setError(null);
    } catch (err) {
      setError('Failed to fetch profile');
      console.error('Profile fetch error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [userId]);

  return {
    profile,
    loading,
    error,
    refreshProfile: fetchProfile
  };
}