import React from 'react';
import { Link } from 'react-router-dom';
import { Megaphone } from 'lucide-react';
import { User } from '../../types';
import { UserMenu } from './UserMenu';

interface HeaderProps {
  user: User | null;
  onSignOut: () => void;
}

export function Header({ user, onSignOut }: HeaderProps) {
  return (
    <header className="fixed top-0 w-full bg-gray-900/80 backdrop-blur-lg border-b border-gray-800 z-40">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center gap-2">
            <div className="p-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg">
              <Megaphone className="w-6 h-6 text-white" />
            </div>
            <span className="text-xl font-bold gradient-text">Sootra Hub</span>
          </Link>

          {user && (
            <div className="flex items-center gap-6">
              <nav className="hidden md:flex items-center gap-6">
                <Link 
                  to="/dashboard" 
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  Dashboard
                </Link>
                {user.role === 'promoter' && (
                  <Link 
                    to={`/creators/${user.id}`} 
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    Public Profile
                  </Link>
                )}
                <Link 
                  to="/profile" 
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  Settings
                </Link>
              </nav>
              <UserMenu user={user} onSignOut={onSignOut} />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}