import React from 'react';
import { Link as LinkIcon, Instagram, Twitter, Youtube, Globe } from 'lucide-react';
import type { CreatorProfile } from '../../types/profile';

interface PublicProfileProps {
  profile: CreatorProfile;
  username: string;
}

export function PublicProfile({ profile, username }: PublicProfileProps) {
  const getSocialIcon = (platform: string) => {
    switch (platform) {
      case 'instagram': return <Instagram className="w-5 h-5" />;
      case 'twitter': return <Twitter className="w-5 h-5" />;
      case 'youtube': return <Youtube className="w-5 h-5" />;
      default: return <Globe className="w-5 h-5" />;
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="glass-card p-8">
        {/* Profile Header */}
        <div className="flex items-center gap-6 mb-8">
          {profile.profilePicture ? (
            <img
              src={profile.profilePicture}
              alt={username}
              className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gradient-to-br from-indigo-500 to-purple-500 flex items-center justify-center text-2xl font-bold text-white">
              {username[0].toUpperCase()}
            </div>
          )}
          
          <div>
            <h1 className="text-2xl font-bold mb-1">{username}</h1>
            <p className="text-gray-400">Creator</p>
          </div>
        </div>

        {/* Bio */}
        <div className="mb-8">
          <h2 className="text-lg font-semibold mb-2">About</h2>
          <p className="text-gray-300">{profile.bio}</p>
        </div>

        {/* Social Links */}
        <div>
          <h2 className="text-lg font-semibold mb-4">Connect</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {Object.entries(profile.socialLinks).map(([platform, url]) => (
              url && (
                <a
                  key={platform}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 p-3 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors"
                >
                  {getSocialIcon(platform)}
                  <span className="text-gray-300">{platform}</span>
                  <LinkIcon className="w-4 h-4 text-gray-400 ml-auto" />
                </a>
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}