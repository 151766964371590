import { 
  ArrowRight,
  ArrowLeft,
  FileText, 
  Shield,
  FileIcon, // Import as FileIcon to avoid conflict with built-in File
  DollarSign,
  Users,
  Bell,
  Globe,
  Scale,
  Clock,
  AlertCircle,
  Lock,
  Eye,
  Database,
  CheckCircle2,
  Megaphone,
  Star,
  Plus,
  Minus,
  Link as LinkIcon,
  Send,
  Image,
  Video,
  TrendingUp,
  Target,
  Wallet,
  Building2,
  CreditCard,
  X,
  MessageCircle,
  Search,
  Filter,
  SlidersHorizontal,
  Camera,
  ExternalLink,
  Download,
  Trash2,
  Paperclip
} from 'lucide-react';

// Custom icon aliases to avoid naming conflicts
export const DocumentIcon = FileIcon;
export const ContractIcon = FileIcon;
export const LegalIcon = FileIcon;

// Re-export all icons
export {
  ArrowRight,
  ArrowLeft,
  FileText,
  Shield,
  DollarSign,
  Users,
  Bell,
  Globe,
  Scale,
  Clock,
  AlertCircle,
  Lock,
  Eye,
  Database,
  CheckCircle2,
  Megaphone,
  Star,
  Plus,
  Minus,
  LinkIcon,
  Send,
  Image,
  Video,
  TrendingUp,
  Target,
  Wallet,
  Building2,
  CreditCard,
  X,
  MessageCircle,
  Search,
  Filter,
  SlidersHorizontal,
  Camera,
  ExternalLink,
  Download,
  Trash2,
  Paperclip
};