import React from 'react';
import { PageTitle } from '../layout/PageTitle';

export function PrivacyPolicy() {
  return (
    <>
      <PageTitle 
        title="Privacy Policy"
        description="How we collect, use, and protect your personal information"
      />

      <div className="glass-card p-8 space-y-6">
        <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">1. Information We Collect</h2>
          <div className="space-y-2 text-gray-300">
            <p>1.1. Account Information: Email, username, profile details</p>
            <p>1.2. Content: Posts, submissions, messages</p>
            <p>1.3. Usage Data: Platform activity, interactions</p>
            <p>1.4. Payment Information: Transaction records, payment methods</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">2. How We Use Your Information</h2>
          <div className="space-y-2 text-gray-300">
            <p>2.1. Provide and improve our services</p>
            <p>2.2. Process payments and transactions</p>
            <p>2.3. Communicate with you about campaigns and updates</p>
            <p>2.4. Analyze platform usage and trends</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">3. Information Sharing</h2>
          <div className="space-y-2 text-gray-300">
            <p>3.1. With brands and creators for campaign purposes</p>
            <p>3.2. With service providers who assist our operations</p>
            <p>3.3. When required by law or to protect rights</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">4. Data Security</h2>
          <div className="space-y-2 text-gray-300">
            <p>4.1. We use industry-standard security measures</p>
            <p>4.2. Regular security audits and updates</p>
            <p>4.3. Encrypted data transmission and storage</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">5. Your Rights</h2>
          <div className="space-y-2 text-gray-300">
            <p>5.1. Access your personal information</p>
            <p>5.2. Request corrections or deletions</p>
            <p>5.3. Opt-out of marketing communications</p>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-xl font-semibold">6. Cookies and Tracking</h2>
          <div className="space-y-2 text-gray-300">
            <p>6.1. We use cookies for functionality and analytics</p>
            <p>6.2. You can control cookie settings in your browser</p>
          </div>
        </section>

        <div className="mt-8 p-4 bg-gray-800 rounded-lg">
          <p className="text-sm text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </>
  );
}