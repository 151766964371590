import React from 'react';
import { CheckCircle2, ArrowRight, Users } from 'lucide-react';

interface SuccessMessageProps {
  onDemoAccess: (role: 'brand' | 'creator') => void;
}

export function SuccessMessage({ onDemoAccess }: SuccessMessageProps) {
  return (
    <div className="max-w-md mx-auto">
      <div className="glass-card p-8 text-center">
        <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-green-500/10 flex items-center justify-center">
          <CheckCircle2 className="w-8 h-8 text-green-400" />
        </div>
        <h3 className="text-2xl font-bold mb-2">You're on the List!</h3>
        <p className="text-gray-400 mb-8">
          Thanks for joining! While you wait, try out our demo to see what's coming.
        </p>
        <div className="flex flex-col sm:flex-row gap-4">
          <button 
            onClick={() => onDemoAccess('brand')} 
            className="button-primary flex-1"
          >
            Try Brand Demo <ArrowRight className="w-5 h-5" />
          </button>
          <button 
            onClick={() => onDemoAccess('creator')} 
            className="button-secondary flex-1"
          >
            Try Creator Demo <Users className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}